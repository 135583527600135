<template>
  <v-layout row wrap>
    <v-flex>
      <div class="white mt-8">
        <h3 class="green--text text-center">
          ACADEMIC PURCHASED USERS
          <p>{{ district.toUpperCase() }}</p>
        </h3>
      </div>

      <v-flex md12 xs12 mt-8 mb-6 class="white px-5 ">
        <v-row class="pt-2">
          <v-col cols="6" sm="6" md="2">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="30px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="From Date"
                  prepend-icon="mdi-calendar"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="green"
                  type="date"
                  dense
                >
                </v-text-field>
              </template>
              <v-date-picker v-model="date" no-title scrollable color="green">
                <v-spacer></v-spacer>
                <v-btn text color="green" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="green" @click="$refs.menu.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="6" sm="6" md="2">
            <v-menu
              ref="menuOne"
              v-model="menuOne"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateOne"
                  label="To Date"
                  prepend-icon="mdi-calendar"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="green"
                  type="date"
                  dense
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="dateOne"
                no-title
                scrollable
                color="green"
              >
                <v-spacer></v-spacer>
                <v-btn text color="green" @click="menuOne = false">
                  Cancel
                </v-btn>
                <v-btn text color="green" @click="$refs.menuOne.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="10" sm="10" md="2">
            <v-btn
              block
              class="green mx-12"
              dark
              @click="filterDate(date, dateOne)"
            >
              <b>FILTER</b>
            </v-btn>
          </v-col>
        </v-row>
      </v-flex>
      <v-flex xs12 class="white pa-5" v-if="filter == false">
        <v-layout row wrap align-center>
          <v-flex md9 xs12 sm12 class="mt-5 mx-6" style="text-align:center">
            <v-text-field
              color="green"
              label="Phone"
              single-line
              outlined
              v-model="phone_number"
              @keydown.enter="search"
            ></v-text-field>
          </v-flex>
          <v-flex md2 xs12 class="mx-5 mb-2">
            <v-btn class="green white--text" block @click="search" height="55px"
              >search
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex style="text-align:center" class="ma-10">
        <v-progress-circular indeterminate color="green" v-if="loading">
        </v-progress-circular
      ></v-flex>
    </v-flex>

    <v-flex xs12 md12>
      <v-flex xs12 md12 class="white pa-3 pb-1" v-if="filter == false">
        <p class="green--text pt-2" v-if="distCount == 0 && phone_number == ''">
          <b>TOTAL PURCHASES : {{ count }}</b>
        </p>
        <p class="green--text pt-2" v-if="distCount != 0">
          <b>TOTAL DISTRICT-WISE PURCHASES : {{ distCount }}</b>
        </p>
      </v-flex>
      <v-flex xs12 md12 class="mt-10 white pa-3 pb-1" v-else>
        <p class="green--text pt-2">
          <b>TOTAL PURCHASES : {{ purchases.length }}</b>
        </p>
      </v-flex>
      <v-simple-table class="my-6">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left green--text">
                #
              </th>
              <th class="text-left green--text">
                NAME
              </th>
              <th class="text-left green--text">
                PHONE
              </th>
              <th class="text-left green--text">
                EMAIL
              </th>
              <th class="text-left green--text">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed small v-bind="attrs" v-on="on" text>
                      <span class="text-left green--text"
                        ><b>DISTRICT</b> <v-icon left top>mdi-menu-down</v-icon>
                      </span>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="(item, index) in items" :key="index">
                      <v-list-item-title
                        ><v-btn
                          class="text-left green--text"
                          depressed
                          @click="filterDistrict(item.title)"
                          >{{ item.title }}</v-btn
                        >
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </th>
              <th class="text-left green--text">
                LOCALITY
              </th>
              <th class="text-left green--text">
                PLATFORM
              </th>
              <th class="text-left green--text">
                COURSE
              </th>
              <th class="text-left green--text">
                PURCHASED DATE
              </th>

              <th class="text-left green--text">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed small v-bind="attrs" v-on="on" text>
                      <span class="text-left green--text"
                        ><b>PAYMENT</b> <v-icon left top>mdi-menu-down</v-icon>
                      </span>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="(item, index) in status" :key="index">
                      <v-list-item-title
                        ><v-btn
                          class="text-left green--text"
                          depressed
                          @click="filterPayment(item.status)"
                          >{{ item.status }}</v-btn
                        >
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </th>
              <th class="text-left green--text">
                ACTION
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in purchases" :key="item._id">
              <td>{{ index + 1 }}</td>

              <td v-if="item.user != null" style="width: 10px;">
                <b>{{ item.user.name }}</b>
              </td>
              <td v-else><b>NA</b></td>
              <td v-if="item.user != null" style="width: 10px;">
                {{ item.user.phone }}
              </td>
              <td v-else>NA</td>
              <td v-if="item.user != null && item.user.email">
                {{ item.user.email }}
              </td>
              <td v-else>NA</td>
              <td
                v-if="item.user != null && item.user.district"
                style="width: 10px;"
              >
                {{ item.user.district }}
              </td>
              <td v-else>NA</td>
              <td
                v-if="item.user != null && item.user.locality"
                style="width: 10px;"
              >
                {{ item.user.locality }}
              </td>
              <td v-else>NA</td>
              <td v-if="item.platform == 0 && item.platform">IOS</td>
              <td v-if="item.platform == 1 && item.platform">Android</td>
              <td v-if="!item.platform" style="width: 10px;">Android / IOS</td>

              <td v-if="item.user != null">{{ item.course }}</td>
              <td v-else>NA</td>
              <td v-if="item.created" style="width: 40px;">
                {{ item.created | moment("Do MMM  YYYY, h:mm:ss a") }}
              </td>
              <td v-else>NA</td>

              <td v-if="item.payment" class="purple--text" style="width: 10px;">
                {{ item.payment }}
              </td>
              <td v-else class="red--text" style="width: 10px;">
                {{ item.payment }}
              </td>
              <td style="width: 100px;">
                <v-icon
                  class="green--text pr-1"
                  @click="gotoDetails(item.user.phone)"
                  >search</v-icon
                >
                <v-icon
                  v-if="ADMIN"
                  class="pink--text "
                  @click="deleteCourse(item._id)"
                  >delete</v-icon
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-flex>
  </v-layout>
</template>

<script>
import { BASE_URL } from "../config";
import Axios from "axios";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      userDetails: {},
      purchases: [],
      sorted_list: [],
      count: "",
      phone_number: "",
      distCount: "",
      status: [{ status: true }, { status: false }],
      items: [
        { title: "Alappuzha" },
        { title: "Ernakulam" },
        { title: "Idukki" },
        { title: "Kannur" },
        { title: "Kasaragod" },
        { title: "Kollam" },
        { title: "Kottayam" },
        { title: "Kozhikode" },
        { title: "Malappuram" },
        { title: "Palakkad" },
        { title: "Pathanamthitta" },
        { title: "Thiruvananthapuram" },
        { title: "Thrissur" },
        { title: "Wayanad" },
        { title: "Others" },
      ],
      district: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      dateOne: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menuOne: false,
      modalOne: false,
      menu2One: false,
      filter: false,
      loading: false,
      startDate: null,
      endDate: null,
    };
  },

  computed: {
    ...mapGetters(["ADMIN"]),

    filteredData() {
      if (!this.startDate || !this.endDate) {
        return this.purchases;
      } else if (this.startDate && this.endDate) {
        const startDate = moment.utc(this.startDate).startOf("day");
        const endDate = moment.utc(this.endDate).endOf("day");
        return this.purchases.filter((item) => {
          if (item.created) {
            const itemDate = moment.utc(item.created);
            return (
              itemDate.isSameOrAfter(startDate) &&
              itemDate.isSameOrBefore(endDate)
            );
          }
        });
      }
    },
  },

  watch: {
    async phone_number(newphone_number, oldphone_number) {
      this.loading = true;
      if (newphone_number.length > 0) {
        let url = BASE_URL + "all-filtered-request?phone=" + newphone_number;
        let { data } = await Axios.get(url);
        this.purchases = data;
        this.loading = false;
      }
    },
  },
  async mounted() {
    {
      this.loading = true;
      let url = BASE_URL + "/all-purchase-request";
      let { data } = await Axios.get(url);
      this.purchases = data;
      // this.purchases.sort((a,b)=> (a.course < b.course ? 1 : -1))
      this.count = this.purchases.length;
      this.loading = false;
    }
  },
  methods: {
    // filterData(startDate,endDate) {
    //   this.startDate = startDate
    //   this.endDate = endDate
    //   console.log('Filtered Data:', this.filteredData);

    // },
    search() {
      if (this.searchText != "") {
        this.$router.push({
          name: "SearchResult",
          params: { phone: this.phone_number },
        });
      }
    },
    gotoDetails(phone) {
      this.$router.push({ name: "SearchResult", params: { phone: phone } });
    },
    editDetails(id) {
      this.$router.push({ name: "EditUser", params: { id: id } });
    },
    async filterDistrict(district) {
      let url = BASE_URL + "/all-purchase-request";
      let { data } = await Axios.get(url);
      this.district = district;
      let filteredPurchases = data;
      filteredPurchases = filteredPurchases.filter((item) => {
        if (item.user != null && item.user.district != null) {
          return item.user.district.toUpperCase() == district.toUpperCase();
        } else if (district == "Others") {
          return item.user.district == null;
        }
      });
      this.distCount = filteredPurchases.length;
      return (this.purchases = filteredPurchases);
    },

    async filterPayment(status) {
      this.loading = true;
      let url = BASE_URL + "/all-purchase-request";
      let { data } = await Axios.get(url);
      let filteredPayment = data;
      filteredPayment = filteredPayment.filter((item) => {
        return item.payment == status;
      });
      this.count = filteredPayment.length;
      this.loading = false;
      return (this.purchases = filteredPayment);
    },

    deleteCourse(id) {
      swal({
        title: "Are you sure?",
        text: "The Course will be deleted permanantly!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let url = BASE_URL + "/delete-course?id=" + id;
          let { data } = await Axios.delete(url);
          await swal("Deleted", "Course deleted successfully!", {
            icon: "success",
          });
          this.$router.go("");
        } else {
          swal("The Course was not deleted!");
        }
      });
    },
    async filterDate(from, to) {
      this.loading = true;
      if (from > to) {
        this.$toasted.error("From DATE must not larger than To DATE!!!");
      } else {
        let payload = {
          previous: from,
          next: to,
        };
        let url = BASE_URL + "/auth/dateSort";
        let { data } = await Axios.post(url, payload);
        this.purchases = data.academy.reverse();
        console.log(data.academy);
        this.filter = true;
        this.loading = false;
      }
    },
  },
};
</script>
