<template class="">
   <v-layout row wrap style="height:100%">
        <v-flex class="xs12 md6 offset-md-3">
            <div class="flex pa-10 mt-13 white elevatin-2 xs12">
             <div class="vertical-spacing"></div>
              <div class="text-center">
                <img src="../assets/logo.png" width="200px" />
                 <h3 class="mt-5 green--text text--darken-4">LOGIN</h3>
              </div>
                 <br>
                 <br>
             <v-row dense >
              <v-col cols="12" md="10" offset="1" class="px-7">
                 <v-text-field color="green" single-line outlined v-model="passcode" class="mt-10" label="Passcode" type="password" @keydown.enter="login"> 
                 </v-text-field>
              </v-col>
             <v-col cols="12" md="12" align="center">
              <v-btn  @click="login" rounded large class="green white--text pl-10 pr-10">Login</v-btn>    
             </v-col>
             </v-row>
            </div>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    data(){
        return{
            passcode : ""
        }
    },
    methods : {
        login(){
            if(this.passcode == 'Admin@123'){
                this.$store.dispatch("SET_LOGIN",true)
                if(this.$route.path != '/view-all-users'){
                this.$router.push('/')
                }
                this.$store.dispatch("SET_ADMIN",false)
              }
          else if(this.passcode == 'PranaIn!@#456'){
                this.$store.dispatch("SET_ADMIN",true)
                if(this.$route.path != '/view-all-users'){
                this.$router.push('/')
                } 
                this.$store.dispatch("SET_LOGIN",false )
                }
            else{
                alert("WRONG PASSCODE")
            }
        }
        
    }
}
</script>
