<template>
    <v-layout row wrap>
       <v-flex md12>
            <div class="white mt-8">
                    <h3 class="green--text text-center py-1">
                        EDIT USER DETAILS
                    </h3>
              </div>
        </v-flex>
 <v-flex md7 class="white pt-4 mt-5" pl-3 pr-3>
     <p class="caption mb-2  font-weight-bold  ma-0"> 
         NAME 
     </p>
<v-text-field single-line outlined label="Name" v-model="userDetails.name">

</v-text-field>
  <p class="caption mb-2  font-weight-bold  ma-0" > 
         PHONE 
     </p>
<v-text-field single-line label="Phone" disabled outlined v-model="userDetails.phone">

</v-text-field>
  <p class="caption mb-2  font-weight-bold  ma-0"> 
         DISTRICT 
     </p>

     <v-select single-line outlined v-model="userDetails.district" label="Select District" :items="districts"></v-select>

  <p class="caption mb-2  font-weight-bold  ma-0"> 
         LOCALITY 
     </p>
<v-text-field single-line label="Locality" outlined v-model="userDetails.locality">

</v-text-field>
  <p class="caption mb-2 font-weight-bold  ma-0"> 
         POST OFFICE 
     </p>
<v-text-field label="Post Office" single-line outlined v-model="userDetails.po">

</v-text-field>
  <p class="caption mb-2  font-weight-bold  ma-0"> 
         PIN CODE 
     </p>
<v-text-field label="Pin Code" single-line outlined v-model="userDetails.pincode">

</v-text-field>
  <v-flex md3 offset-md9 class="text-center">
 <v-btn color="green" class="white--text mt- mb-8" x-large block @click="saveDetails" name="button" type="submit"> SAVE</v-btn>
   
  </v-flex>
 </v-flex>
    </v-layout>
</template>

<script>
import { BASE_URL } from '../config'
import Axios from 'axios'

export default {
    data(){
        return {
             districts : ["Alappuzha","Ernakulam","Idukki","Kannur","Kasaragod","Kollam","Kottayam","Kozhikode","Malappuram","Palakkad","Pathanamthitta","Thiruvananthapuram","Thrissur","Wayanad"],
            userDetails : [],
            // purchases : [],
            // sorted_list : []
        }
    },
      methods:{
         async saveDetails(){
             {
                let url = BASE_URL+"/edit-user-details?id="+ this.$route.params.id
                let payload = {
                 
                    name  : this.userDetails.name,
                    po  : this.userDetails.po,
                    phone  : this.userDetails.phone,
                    pincode  : this.userDetails.pincode,
                    locality  : this.userDetails.locality,
                    district  : this.userDetails.district,
                   
                }
                let {data} = await Axios.put(url,payload)
                this.$toastr.s("Details edited successfully!!", "Details edited");
                   this.$router.push({name: 'viewAllUsers'})
                 
            }
         } 
},
    async mounted(){
     
        {
            let url = BASE_URL + "/auth/users/details?id="+ this.$route.params.id
            let {data} = await Axios.get(url)
            this.userDetails = data
          //  this.purchases.sort((a,b)=> (a.course < b.course ? 1 : -1))
        }        
    }
}
</script>