<template>
    <v-layout row wrap>
          <v-flex xs12 md7 pl-3>
               <div class="white mt-10">
                    <h3 class="green--text text-center py-1">
                        USER DETAILS
                    </h3>
              </div>
                <v-simple-table class="mt-4">
                        <template v-slot:default>
                       
                        <tbody>
                            <tr>
                                <td><b>NAME</b></td>
                                <td>{{Details.name}}</td>
                            </tr>
                            <tr>
                                <td><b>PHONE</b></td>
                                <td>{{Details.phone}}</td>
                            </tr>
                            <tr>
                                <td><b>DISTRICT</b></td>
                                <td>{{Details.district}}</td>
                            </tr>
                            <tr>
                                <td><b>LOCALITY</b></td>
                                <td>{{Details.locality}}</td>
                            </tr>
                            <tr>
                                <td><b>PINCODE</b></td>
                                <td>{{Details.pincode}}</td>
                            </tr>
                            <tr>
                                <td><b>POST OFFICE</b></td>
                                <td>{{Details.po}}</td>
                            </tr>
                            <tr>
                                <td><b>DEVICE ID </b></td>
                                <td class="pink--text">{{Details.device_id}}</td>
                            </tr>
                            <tr>
                                <td><b>VERIFIED </b></td>
                                <td class="indigo--text">{{Details.verified}}</td>
                            </tr>
                        </tbody>
                        </template>
                </v-simple-table>
        </v-flex>
          <v-flex offset-md1 m4>
              <div class="white mt-10">
                    <h3 class="green--text text-center py-1">
                        COURSE DETAILS
                    </h3>
              </div>
                <v-simple-table  class="mt-2 ">
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left green--text">
                                #
                            </th>
                         
                            <th class="text-left green--text">
                                COURSE
                            </th>
                            <!-- <th class="text-left green--text">
                                LOCALITY
                            </th> -->
                            <!-- <th class="text-left green--text">
                                ACTION
                            </th> -->
                            </tr>
                        </thead>
                        <tbody v-if="userDetails.length >0">
                            <tr
                                v-for="(item,index) in userDetails"
                                :key="item._id"
                            >
                            <td>{{ index+1 }}</td>
                     
                            
                            <td>{{ item.course }}</td>
                            <!-- <td>{{ item.user.locality }}</td> -->
                            <!-- <td>
                                <v-icon class="pink--text" @click="editDetails(item._id)">edit</v-icon> 
                                
                            </td> -->
                            </tr>
                        </tbody>
                        <tbody v-else>
                        <tr class="text-center" >  No purchases yet!!
                              </tr> 
                            </tbody>
                           
                        </template>
                </v-simple-table>
        </v-flex> 
        <v-flex  xs12 md7 pl-3>
              <div class="white mt-5">
                    <h3 class="green--text text-center py-1">
                       FRANCHISE DETAILS
                    </h3>
              </div>
                <v-simple-table class="mt-4">
                        <template v-slot:default>
                
                        <tbody>
                            <tr>
                                <td><b>NAME</b></td>
                                <td>{{purchases.name}}</td>
                            </tr>
                            <tr>
                                <td><b>PHONE</b></td>
                                <td>{{purchases.phone}}</td>
                            </tr>
                              <tr>
                                <td><b>EMAIL</b></td>
                                <td>{{purchases.email}}</td>
                            </tr>
                            <tr>
                                <td><b>DISTRICT</b></td>
                                <td>{{purchases.district}}</td>
                            </tr>
                            <tr>
                                <td><b>LOCALITY</b></td>
                                <td>
                                    <span v-for="(i, index) in purchases.locality" :key="index">
                                    <span v-if="index == purchases.locality.length - 1">
                                            {{ i }}
                                    </span>
                                    <span v-else> 
                                        {{ i }} ,
                                    </span>
                                
                                </span>
                                </td>
                            </tr>
                            <tr>
                                <td><b>ADDRESS</b></td>
                                <td>{{purchases.address}}</td>
                            </tr>
                            <!-- <tr>
                                <td><b>POST OFFICE</
                                b></td>
                                <td>{{Details.po}}</td>
                            </tr>
                            <tr>
                                <td><b>DEVICE ID </b></td>
                                <td class="pink--text">{{userDetails.device_id}}</td>
                            </tr>
                            <tr>
                                <td><b>VERIFIED </b></td>
                                <td class="indigo--text">{{userDetails.verified}}</td>
                            </tr> -->
                         </tbody>
                     </template>
                 </v-simple-table>
            </v-flex>
        </v-layout>
</template>

<script>
import { BASE_URL } from '../config'
import Axios from 'axios'

export default {
    data(){
        return {
            userDetails : {},
            purchases :{},
            Details : [],
            sorted_list : []
        }
    },
   
    async mounted(){
        {
            let url = BASE_URL + "/auth/users/details?id="+this.$route.params.user
            let {data} = await Axios.get(url)
            this.Details = data
        }
       {
            let url = BASE_URL + "/user-purchase?id="+this.$route.params.user
            let {data} = await Axios.get(url)
            this.userDetails = data
        }  
        {
            //http://3.7.194.167:5006/api/v1/purchase-by-course-request?code=LAB-M
            let url = BASE_URL + "/get-franchise/details?id=" + this.$route.params.id
            let {data} = await Axios.get(url)
            this.purchases = data

          
            //this.purchases.sort((a,b)=> (a.franchise < b.franchise ? 1 : -1))
        }        
    }
}
</script>