<template>
  <div class="">
<v-navigation-drawer 
        v-model="drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app 
        mini-variant-width="220"
       

      >
     
      <!-- <div class="mt-0 text-center white"> 
        
       <img class="mt-4" src="../assets/savebox.png" style="height:80px; width:75px;" />
      <v-btn @click="home()" text class="mt-0 ml-n2 mb-4 purple--text" style="font-size:16px;"><b>Savebox | Dashboard</b></v-btn>
       </div> -->
       <div>
        <v-list dense class="mt-4">
          <template v-for="item in items">
            <v-row
              v-if="item.heading"
              :key="item.heading"
              align="center"
            >
              <v-col cols="6" >
                <v-subheader v-if="item.heading">
                 {{ item.heading }}
                </v-subheader>
              </v-col>
              <v-col
                cols="6"
                class="text-center"
              >
                <a
                  href="#!"
                  class="body-2 green--text"
                >EDIT</a>
              </v-col>
            </v-row>
            <v-list-group
             class="green--text mt-3"
             v-else-if="item.children"
              :key="item.text"
              link
              @click="selectItem(item.text)"
              
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="green--text">
                    <v-icon class="green--text mr-8">{{ item.icon }}</v-icon>
                    <b class="green--text">{{ item.text }}</b>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(child, i) in item.children"
                :key="i"
                class=""
                link
                @click="selectItem(child.text)"
              >
                <v-list-item-action v-if="child.icon">
                  <v-icon class="white--text">{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item
              v-else
              :key="item.icon"
              link
              class="mt-3"
              @click="selectItem(item.text)"
             
              :to="item.link"
              :class="item.text === $route.path ? 'highlighted' : ''"
            >
              <v-list-item-action>
                <v-icon class="green--text">{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="green--text">
                  <b>{{ item.text }}</b>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
       </div>
      </v-navigation-drawer>
       <v-app-bar class="white"
       :clipped-left="$vuetify.breakpoint.lgAndUp"
       app
       >
          <v-icon @click.stop="drawer = !drawer">menu</v-icon>
        <v-toolbar-title
          class="ml-0 pl-2"
        >
          <img @click="home" src="../assets/logo.png" style="max-height:72px"/>
        </v-toolbar-title>

  </v-app-bar>
  </div>
 
</template>

<script>
import {mapGetters} from 'vuex'
  export default {
    computed : {
      ...mapGetters(['PROGRESS','USER','LOGIN'])
    },
    data: () => ({
      dialog: false,
      drawer: false,
      
      items: [ 
        // {
        //   icon: 'arrange-send-backward',
        //   'icon-alt': 'edit',
        //   text: 'All Courses',
        //   model: false,
        // },
        // {
        //   icon: 'notification_important',
        //   'icon-alt': 'notification_important',
        //   text: 'License Requests',
        //   model: false,
        // },
           {
          icon: 'mdi-account-multiple-outline',
          'icon-alt': 'mdi-account-multiple-outline',
          text: 'All Users',
          model: false,
          link : "/"
        }, 
        
         {
          icon: 'mdi-cart-outline',
          'icon-alt': 'shopping_basket',
          text: 'Purchased Users(Academic)',
          model: false,
          link : "/AllPurchases"
        }, 
        {
          icon: 'mdi-cart',
          'icon-alt': 'shopping_basket',
          text: 'Purchased Users (TA)',
          model: false,
          link : "/AllPurchasesTa"
        },
      
        
        {
          icon: 'mdi-currency-rupee',
          'icon-alt': 'supervisor_account',
          text: 'ASCC Purchased Users',
          model: false,
          link : "/ascc"
        },
        {
          icon: 'mdi-currency-usd',
          'icon-alt': 'supervisor_account',
          text: 'M-Cube Purchsed Users',
          model: false,
          link : "/mcube"
        },
        {
          icon: 'mdi-presentation-play',
          'icon-alt': 'mdi-presentation-play',
          text: 'All Courses',
          model: false,
          link : "/Courses"
        },
        {
          icon: 'power_settings_new',
          'icon-alt': 'power_settings_new',
          text: 'Logout',
          model: false,
        },
      ]
    }),
    methods : {
      home(){
          this.$router.push('/')
      },
      selectItem(item){
        // if(item == "Purchased Users(Academic)"){
        //     this.$router.push({name : 'AllPurchases'})
            
            //this.$store.dispatch("GET_ALL_CATEGORIES")
        // }
        // if(item == "Purchased Users (TA)"){
        //     this.$router.push({name : 'AllPurchasesTa'})
        // }
        // if(item == "Franchises"){
        //     this.$router.push({name : 'ViewFranchises'})
        //     //this.$store.dispatch("GET_ALL_CATEGORIES")
            
        // }
        // if(item == "ASCC Purchsed Users"){
        //     this.$router.push('/ascc')
        //     //this.$router.go()
        // }
        //  if(item == "M-Cube Purchsed Users"){
        //     this.$router.push('/mcube')
        // }
        //  if(item == "Registered Users"){
        //     this.$router.push('/RegisteredUsers')
        // }
        //  if(item == "All Users"){
        //     this.$router.push('/view-all-users')
        // }
       //   if (item == "Logout") {
      //   let context = this;
      //   this.$swal({
      //     title: "Are you sure?",
      //     text: "Unsaved changed will be lost!!",
      //     icon: "warning",
      //     showCancelButton: true,
      //     confirmButtonColor: "#00C853",
      //     cancelButtonColor: "#d33",
      //     confirmButtonText: "Yes, logout !",
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       //LOGOUT LOGIC

      //       context.$swal.fire("Success", "Logout Success", "success");
      //       let payload = null;
      //       context.$store.dispatch("USER_LOGOUT", payload);
      //       this.$router.push("/");
      //     }
      //   });
      // }
       if(item == "Logout"){
          this.$store.dispatch("SET_LOGIN",false)
          this.$store.dispatch("SET_ADMIN",false)
          this.$router.push('/login')

        }
        // if(item == "All Courses"){
          
        //   this.$router.push('/Courses')

        // }
      // if(item == "Add User"){
      //     this.$router.push('/users/add-admin')
      //   }
      // if(item == "View Users"){
      //     this.$router.push('/users/view-admins')
      //   }
      //  if(item == "Localities"){
      //     this.$router.push('/localities')
      //   }
      //    if(item == "Add Invitation"){
      //   this.$router.push('/add-invitation')
      // }
      //    if(item == "View Invitations"){
      //   this.$router.push('/view-invitations')
      // }
      },
     
      // clickChild(child){

        
      //   if(child.text == "Filter By Month"){
      //       this.$router.push('/orders/monthly')
      //   }
      //   if(child.text == "Filter By Date"){
      //       this.$router.push('/orders/date-range')
      //   }
        
        
      // }
    },
    props: {
      source: String
    },

  }
</script>
