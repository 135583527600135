import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ViewAllUsers from '../components/AllUsers'
import AllPurchases from '../components/AllPurchases'
import AllPurchasesTa from '../components/AllPurchasesTa'
import UserDetails from '../components/UserDetails'
import Courses from '../components/Courses'
import CourseWisePurchase from '../components/CourseWisePurchase'
import CourseWisePurchaseTa from '../components/CourseWisePurchaseTa'
import RegisteredUsers from '../components/RegisteredUsers'
import EditUser from '../components/EditUser'
import EditCourse from '../components/EditCourse'
import EditCourseDetails from '../components/EditCourseDetails'
import FranchiseDetail from '../components/FranchiseDetail'
import SearchResult from '../components/SearchResult'
import LoginCard from '../components/LoginCard'
import Mcube from '../components/Mcube'
import ASCC from '../components/Ascc'



Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  
  {
    path: '/login',
    name: 'LoginCard',
    component: LoginCard
  },
  
{
  path : '/AllPurchases',
  name : 'AllPurchases',
  component : AllPurchases
},
{
  path : '/AllPurchasesTa',
  name : 'AllPurchasesTa',
  component : AllPurchasesTa
},
{
  path : '/ascc',
  name : 'ASCC',
  component : ASCC
},
{
  path : '/mcube',
  name : 'Mcube',
  component : Mcube
},
{
  path : '/',
  name : "viewAllUsers",
  component : ViewAllUsers
},

{
  path : '/Courses',
  name : "Courses",
  component : Courses
},
{
  path : '/user-details/:id',
  name : "userDetails",
  component : UserDetails
},
{
  path : '/search-result/:phone',
  name : "SearchResult",
  component : SearchResult
},
{
  path : '/CourseWisePurchase/:code/:title',
  name : "CourseWisePurchase",
  component : CourseWisePurchase
},
{
  path : '/CourseWisePurchaseTa/:id/:title',
  name : "CourseWisePurchaseTa",
  component : CourseWisePurchaseTa
},
{
  path : '/RegisteredUsers',
  name : "RegisteredUsers",
  component : RegisteredUsers
},

{
  path : '/EditUser/:id',
  name : "EditUser",
  component : EditUser
},

{
  path : '/EditCourse/:id',
  name : "EditCourse",
  component : EditCourse
},

{
  path : '/EditCourseDetails/:id',
  name : "EditCourseDetails",
  component : EditCourseDetails
},
{
  path : '/FranchiseDetail/:id/:user',
  name : "FranchiseDetail",
  component : FranchiseDetail
},

]

const router = new VueRouter({
  routes
})

export default router
