<template>
  <v-layout row wrap>
    <v-flex xs12>
      <div class="white mt-8">
        <h3 class="green--text text-center">
          ALL REGISTERED USERS
        </h3>
      </div>
      <div class="text-center mt-10" v-if="phone_number == ''">
        <v-pagination
          v-if="filter == false"
          v-model="page"
          :length="pageLength"
          :total-visible="20"
          @input="gotoPage(page)"
          color="green"
        ></v-pagination>
      </div>
      <v-flex md12 xs12 mt-8 mb-6 class="white px-5 ">
        <v-row class="pt-2">
          <v-col cols="6" sm="6" md="2">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="30px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="From Date"
                  prepend-icon="mdi-calendar"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="green"
                  type="date"
                  dense
                >
                </v-text-field>
              </template>
              <v-date-picker v-model="date" no-title scrollable color="green">
                <v-spacer></v-spacer>
                <v-btn text color="green" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="green" @click="$refs.menu.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="6" sm="6" md="2">
            <v-menu
              ref="menuOne"
              v-model="menuOne"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateOne"
                  label="To Date"
                  prepend-icon="mdi-calendar"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="green"
                  type="date"
                  dense
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="dateOne"
                no-title
                scrollable
                color="green"
              >
                <v-spacer></v-spacer>
                <v-btn text color="green" @click="menuOne = false">
                  Cancel
                </v-btn>
                <v-btn text color="green" @click="$refs.menuOne.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="10" sm="10" md="2">
            <v-btn
              block
              class="green mx-12"
              dark
              @click="filterDate(date, dateOne)"
            >
              <b>FILTER</b>
            </v-btn>
          </v-col>
        </v-row>
      </v-flex>
    </v-flex>
    <v-flex xs12 class="white pa-5">
      <v-layout row wrap align-center>
        <v-flex md9 xs12 sm12 class="mt-5 mx-6" style="text-align:center">
          <v-text-field
            color="green"
            label="Phone"
            outlined
            v-model="phone_number"
            @keydown.enter="search"
          ></v-text-field>
        </v-flex>
        <v-flex md2 xs12 class="mx-5 mb-2">
          <v-btn class="green white--text" block @click="search" height="55px"
            >search
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex style="text-align:center" class="mt-10">
      <v-progress-circular indeterminate color="green" v-if="loading">
      </v-progress-circular
    ></v-flex>
    <v-flex xs12 class="pl-3 pr-3">
      <v-layout row wrap class="mb-5">
        <v-flex xs6 class="mt-10 white pa-3 pb-1" v-if="phone_number == ''">
          <p v-if="filter == false" class="green--text pt-2">
            <b>TOTAL REGISTRATIONS : {{ count }} </b>
          </p>
          <p v-else class="green--text pt-2">
            <b>TOTAL REGISTRATIONS : {{ users.length }}</b>
          </p>
        </v-flex>

        <v-flex
          xs6
          style="text-align:right"
          class="mt-10 white pa-3"
          v-if="phone_number == ''"
        >
          <span v-if="filter == false" class="caption mr-5"
            >showing results <b>{{ skip + 1 }}</b> to
            <b>{{ skip + 1000 }}</b></span
          >
          <!-- <v-btn @click="prev" v-if="skip!=0">PREV</v-btn>
                    <v-btn @click="next" class="ml-5 green white--text">NEXT</v-btn> -->
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex xs12>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left green--text">
                #
              </th>
              <th class="text-left green--text">
                NAME
              </th>
              <th class="text-left green--text">
                PHONE
              </th>
              <th class="text-left green--text">
                DISTRICT
              </th>
              <th class="text-left green--text">
                PINCODE
              </th>
              <th class="text-left green--text">
                LOCALITY
              </th>
              <th class="text-left green--text">
                REGISTERED DATE
              </th>
              <th class="text-left green--text">
                ACTION
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in users" :key="item._id">
              <td width="10px" v-if="filter == false">
                {{ skip + index + 1 }}
              </td>
              <td v-else>{{ index + 1 }}</td>
              <td>
                <b>{{ item.name }}</b>
              </td>
              <td>{{ item.phone }}</td>
              <td>{{ item.district }}</td>
              <td>{{ item.pincode }}</td>
              <td>{{ item.locality }}</td>
              <td v-if="item.created_on">
                {{ item.created_on | moment("Do MMM YYYY, h:mm:ss a") }}
              </td>
              <td v-else>NA</td>
              <td>
                <!-- <v-icon class="pink--text" @click="editDetails(item._id)">edit</v-icon> -->
                <v-icon
                  class="green--text pr-3"
                  @click="viewUserDetails(item._id)"
                  >search</v-icon
                >

                <!-- <v-icon class="pink--text" @click="editDetails(item._id)">edit</v-icon> -->
                <v-icon
                  class="green--text text--darken-4"
                  @click="editUserDetails(item._id)"
                  >edit</v-icon
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-flex>
  </v-layout>
</template>

<script>
import { BASE_URL } from "../config";
import Axios from "axios";

export default {
  data() {
    return {
      users: [],
      skip: 0,
      loading: false,
      count: "",
      phone_number: "",
      searchText: "",
      page: 1,
      pageLength: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      dateOne: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menuOne: false,
      modalOne: false,
      menu2One: false,
      filter: false,
      loading: false,
    };
  },
  async mounted() {
    {
      this.loading = true;
      let url = BASE_URL + "/auth/users/all?skip=" + this.skip;
      let { data } = await Axios.get(url);
      this.users = data;
      this.loading = false;

      //  this.users.sort((a,b)=> (a.district < b.district ? 1 : -1))
    }
    {
      let url = BASE_URL + "/auth/users/count";
      let { data } = await Axios.get(url);
      this.count = data.count;
      this.pageLength = parseInt(data.count / 1000 + 1);
      this.filter = false;
    }
  },

  watch: {
    async phone_number(newphone_number, oldphone_number) {
      this.loading = true;
      if (newphone_number.length > 0) {
        let url = BASE_URL + "auth/users/all-users?phone=" + newphone_number;
        let { data } = await Axios.get(url);
        this.users = data;
      }
      this.loading = false;
    },
  },
  methods: {
    search() {
      this.$router.push({
        name: "SearchResult",
        params: { phone: this.phone_number },
      });
    },
    viewUserDetails(id) {
      this.$router.push({ name: "userDetails", params: { id: id } });
    },
    editUserDetails(id) {
      this.$router.push({ name: "EditUser", params: { id: id } });
    },
    async gotoPage(page) {
      this.users = [];
      this.loading = true;
      this.skip = (page - 1) * 1000;
      let url = BASE_URL + "/auth/users/all?skip=" + this.skip;
      let { data } = await Axios.get(url);
      this.users = data;
      this.loading = false;
    },
    async filterDate(from, to) {
      this.loading = true;
      if (from > to) {
        this.$toasted.error("From DATE must not larger than To DATE!!!");
      } else {
        let payload = {
          previous: from,
          next: to,
        };
        let url = BASE_URL + "/auth/dateSort";
        let { data } = await Axios.post(url, payload);
        this.users = data.users.reverse();
        this.filter = true;
        this.loading = false;
      }
    },
  },
};
</script>
