<template>
    <v-layout row wrap class="">
        <v-flex xs12>
            <v-layout xs12 row wrap >
            <v-flex xs10 pl-3>
              <div class="white mt-10">
                    <h3 class="green--text text-center py-1">
                       ALL COURSES
                    </h3>
              </div>
                <v-simple-table  class="mt-2 ">
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left green--text">
                                #
                            </th>
                         
                            <th class="text-left green--text">
                                COURSE
                            </th>
                            <th class="text-left green--text">
                                ACTION
                            </th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr
                                v-for="(item,index) in courses"
                                :key="item._id"
                            >
                            <td>{{ index+1 }}</td>
                            <td>{{ item.name }}</td>
                            <td>
                                <v-icon class="green--text" @click="viewCourseDetail(item.code,item.name)">search</v-icon>     
                            </td>
                            </tr>
                        </tbody>
                     
                        </template>
                </v-simple-table>
            </v-flex> 
            <v-flex xs10 pl-3>
              <div class="white mt-10">
                    <h3 class="green--text text-center py-1">
                       TALENT ACADEMY
                    </h3>
              </div>
                <v-simple-table  class="mt-2 ">
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left green--text">
                                #
                            </th>
                         
                            <th class="text-left green--text">
                                COURSE
                            </th>
                            <th class="text-left green--text">
                                ACTION
                            </th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr
                                v-for="(item,index) in talentAcademyCourses"
                                :key="item._id"
                            >
                            <td>{{ index+1 }}</td>
                            <td>{{ item.course }}</td>
                            <td>
                                <v-icon class="green--text" @click="viewCourseDetailTa(item._id,item.course)">search</v-icon>     
                            </td>
                            </tr>
                        </tbody>
                     
                        </template>
                </v-simple-table>
            </v-flex> 
            <v-flex xs10 pl-3>
              <div class="white mt-10">
                    <h3 class="green--text text-center py-1">
                       ASHA SHARATH CULTURAL CENTER - CLASSES
                    </h3>
              </div>
                <v-simple-table  class="mt-2 ">
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left green--text">
                                #
                            </th>
                         
                            <th class="text-left green--text">
                                COURSE
                            </th>
                            <th class="text-left green--text">
                                ACTION
                            </th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr
                                v-for="(item,index) in asccCoursesClasses"
                                :key="item._id"
                            >
                            <td>{{ index+1 }}</td>
                            <td>{{ item.course }}</td>
                            <td>
                                <v-icon class="green--text" @click="viewCourseDetailTa(item._id,item.course)">search</v-icon>     
                            </td>
                            </tr>
                        </tbody>
                     
                        </template>
                </v-simple-table>
            </v-flex> 
            <v-flex xs10 pl-3>
              <div class="white mt-10">
                    <h3 class="green--text text-center py-1">
                       ASHA SHARATH CULTURAL CENTER - COMPETITION
                    </h3>
              </div>
                <v-simple-table  class="mt-2 ">
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left green--text">
                                #
                            </th>
                         
                            <th class="text-left green--text">
                                COURSE
                            </th>
                            <th class="text-left green--text">
                                ACTION
                            </th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr
                                v-for="(item,index) in asccCoursesCompetitions"
                                :key="item._id"
                            >
                            <td>{{ index+1 }}</td>
                            <td>{{ item.course }}</td>
                            <td>
                                <v-icon class="green--text" @click="viewCourseDetailTa(item._id,item.course)">search</v-icon>     
                            </td>
                            </tr>
                        </tbody>
                     
                        </template>
                </v-simple-table>
            </v-flex> 
            
               
            </v-layout>
        </v-flex>
    </v-layout>
</template>

<script>
import {BASE_URL} from '@/config.js'
import Axios from 'axios'
export default {
    data(){
        return{
            courses : [],
            asccCoursesClasses : [],
            asccCoursesCompetitions : [],
            talentAcademyCourses : []
        }
    },
    async mounted(){
            {
            let url = BASE_URL + "/talent-academy/course"
            let {data} = await Axios.get(url)
            // this.talentAcademyCourses = data
            for(var i=0;i<data.length;i++){
                // if(this.talentAcademyCourses[i].course == ""){
                //     this.talentAcademyCourses.splice(i,1)
                // }
                
                if(data[i].categoryName != "PRANA ASHA SHARATH CULTURAL CENTRE- CLASSES" && data[i].categoryName != "PRANA ASHA SHARATH CULTURAL CENTRE- COMPETITION"){
                    this.talentAcademyCourses.push(data[i])
                }
              
            }
            }    
            {
                let url = BASE_URL + "/talent-academy/course/category?category=PRANA ASHA SHARATH CULTURAL CENTRE- CLASSES"
                let {data} = await Axios.get(url)
                this.asccCoursesClasses = data
            }
            {
                let url = BASE_URL + "/talent-academy/course/category?category=PRANA ASHA SHARATH CULTURAL CENTRE- COMPETITION"
                let {data} = await Axios.get(url)
                this.asccCoursesCompetitions = data
            }
          {
            let url = BASE_URL + "/course-details/all"
            let {data} = await Axios.get(url)
            this.courses = data
            this.courses.sort((a,b)=> (a.code < b.code ? 1 : -1))
            for(var i=0;i<this.courses.length;i++){
                if(this.courses[i].name == ""){
                    this.courses.splice(i,1)
                }
                if(this.courses[i].category=="ENGLISH TUITION MASTER"){
                    
                    this.courses.splice(i,1)
                }
                if(this.courses[i].name == "STD 03"){
                    this.courses.splice(i,1)
                }
            }

        }
        
    },
    methods:{
        
         viewCourseDetail(code,title){
            this.$router.push({name : 'CourseWisePurchase',params : {code : code , title : title}})

        },
         viewCourseDetailTa(id,title){
            this.$router.push({name : 'CourseWisePurchaseTa',params : {id : id, title : title}})
        },
    }
}
</script>