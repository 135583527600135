<template>
    
    <v-layout row wrap>
<v-flex md12>
   <v-flex xs7 pl-3>
              <div class="white mt-10">
                    <h3 class="green--text text-center py-1">
                        COURSE DETAILS
                    </h3>
              </div>
                <v-simple-table  class="mt-2 ">
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left green--text">
                                #
                            </th>
                         
                            <th class="text-left green--text">
                                COURSE
                            </th>
                            <!-- <th class="text-left green--text">
                                LOCALITY
                            </th> -->
                            <th class="text-left green--text">
                                ACTION
                            </th>
                            </tr>
                        </thead>
                        <tbody v-if="purchases.length >0">
                            <tr
                                v-for="(item,index) in purchases"
                                :key="item._id"
                            >
                            <td>{{ index+1 }}</td>
                     
                            
                            <td>{{ item.course }}</td>
                            <!-- <td>{{ item.user.locality }}</td> -->
                            <td>
                                <v-icon class="pink--text" @click="editDetails(item._id)">edit</v-icon> 
                                
                            </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                        <tr class="text-center" >  No purchases yet!!
                              </tr> 
                            </tbody>
                           
                        </template>
                </v-simple-table>
        </v-flex> 

</v-flex>
    </v-layout>
</template>

<script>
import { BASE_URL } from '../config'
import Axios from 'axios'

export default {
    data(){
        return {
            userDetails : {},
            purchases : [],
            sorted_list : []
        }
    },
    methods:{
        editDetails(id){
   this.$router.push({name : 'EditCourseDetails',params : {id : id}})
          
        }
    },
    async mounted(){
     
        {
            let url = BASE_URL + "/user-registered-course-detail?id="+this.$route.params.id
            let {data} = await Axios.get(url)
            this.purchases = data
          // this.purchases.sort((a,b)=> (a.course < b.course ? 1 : -1))
        }        
    }
}
</script>