<template>
    <v-layout row wrap>
        <v-flex>
            <div class="white mt-8">
                    <h3 class="green--text text-center py-1">
                        REGISTERED USERS
                    </h3>
              </div>
        </v-flex>
   
        <v-flex xs12>
                <v-simple-table class="mt-3">
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left green--text">
                                #
                            </th>
                            <th class="text-left green--text">
                                NAME
                            </th>
                            <th class="text-left green--text">
                                PHONE
                            </th>
                            <th class="text-left green--text">
                                DISTRICT
                            </th>
                            <th class="text-left green--text">
                                COURSE
                            </th>
                            <!-- <th class="text-left green--text">
                                LOCALITY
                            </th> -->
                            <!-- <th class="text-left green--text">
                                ACTION
                            </th> -->
                            </tr>
                        </thead>
                        
                        <tbody>
                            <tr
                                v-for="(item,index) in purchases"
                                :key="index"
                            >
                            <td>{{ index+1 }}</td>
                            <!-- <td>{{item.user.name}}</td> -->
                                <!-- <td><b>{{ item.user.name}}</b></td> -->
                             <!-- <-- <td>{{ item.user.phone }}</td>
                                <td>{{ item.user.district }}</td>
                                <td>{{ item.course }}</td>  --> 
                         
                            <!-- <td>
                               
                                <v-icon class="green--text" @click="viewUserCourseDetails(item._id)">search</v-icon>
                          
                            </td> -->
                            </tr>
                        </tbody>
                        </template>
                </v-simple-table>
        </v-flex>
    </v-layout>
</template>

<script>
import { BASE_URL } from '../config'
import Axios from 'axios'

export default {
    data(){
        return {
            userDetails : {},
            purchases : [],
            sorted_list : []
        }
    },
    methods:{
        viewUserCourseDetails(id){
   this.$router.push({name : 'EditCourse',params : {id : id}})
          
        }
    },
    async mounted(){
     
        {
            let url = BASE_URL + "/registered-users"
            let {data} = await Axios.get(url)
            this.purchases = data

        //   this.purchases.sort((a,b)=> (a.course < b.course ? 1 : -1))
        }        
    }
}
</script>