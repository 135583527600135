<template>
  <v-layout row wrap>
    <v-flex>
      <div class="white mt-8">
        <h3 class="green--text text-center " >
          MOULDING MINDS MAGICALLY PURCHASED USERS
          <p>{{ district.toUpperCase() }}</p>
        </h3>
      </div>
      <v-flex md12 xs12 mt-8 mb-6  class="white px-5 ">
        <v-row class="pt-2">
          <v-col cols="6" sm="6" md="2">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="30px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="From Date"
                  prepend-icon="mdi-calendar"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="green"
                  type="date"
                  dense
                >
                </v-text-field>
              </template>
              <v-date-picker v-model="date" no-title scrollable color="green">
                <v-spacer></v-spacer>
                <v-btn text color="green" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="green" @click="$refs.menu.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="6" sm="6" md="2">
            <v-menu
              ref="menuOne"
              v-model="menuOne"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateOne"
                  label="To Date"
                  prepend-icon="mdi-calendar"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="green"
                  type="date"
                  dense
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="dateOne"
                no-title
                scrollable
                color="green"
              >
                <v-spacer></v-spacer>
                <v-btn text color="green" @click="menuOne = false">
                  Cancel
                </v-btn>
                <v-btn text color="green" @click="$refs.menuOne.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="10" sm="10" md="2" >
            <v-btn
              block
              class="green mx-12"
              dark
              @click="filterDate(date, dateOne)"
             
            >
              <b>FILTER</b>
            </v-btn>
          </v-col>
        </v-row>
      </v-flex>
      <v-flex xs12 class="white pa-5">
      <v-layout row wrap align-center>
        <v-flex md9 xs12 sm12 class="mt-5 mx-6" style="text-align:center">
          <v-text-field
            color="green"
            label="Phone"
            outlined
            v-model="phone_number"
            @keydown.enter="search"
            
          ></v-text-field>
        </v-flex>
        <v-flex md2 xs12 class="mx-5 mb-2" >
          <v-btn
            class="green white--text"
            block
            @click="search"
            height="55px"
            >search
            </v-btn
          >
        </v-flex>
      </v-layout>
    </v-flex>
      <v-flex style="text-align:center" class="ma-10">
        <v-progress-circular indeterminate color="green" v-if="loading">
        </v-progress-circular
      ></v-flex>
    </v-flex>
    <v-flex xs12>
      <v-flex xs12 class=" white pa-3 pb-1">
        <p
          class="green--text pt-2"
          v-if="distCount == 0 && phone_number == '' && filter == false"
        >
          <b>TOTAL PURCHASES (TA) : {{ count }}</b>
        </p>
        <p v-if="distCount == 0 && filter == true" class="green--text pt-2">
          <b>TOTAL PURCHASES (TA) : {{ purchases.length }}</b>
        </p>
        <p class="green--text pt-2" v-if="distCount != 0">
          <b>TOTAL DISTRICT-WISE PURCHASES (TA) : {{ distCount }}</b>
        </p>
      </v-flex>
      <v-simple-table class="my-6">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left green--text">
                #
              </th>
              <th class="text-left green--text">
                NAME
              </th>
              <th class="text-left green--text">
                PHONE
              </th>
              <th class="text-left green--text">
                EMAIL
              </th>
              <th v-if="!filter" class="text-left green--text">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed small v-bind="attrs" v-on="on" text>
                      <span class="text-left green--text"
                        ><b>DISTRICT</b> <v-icon>mdi-menu-down</v-icon>
                      </span>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="(item, index) in items" :key="index">
                      <v-list-item-title>
                        <v-btn
                          class="text-left green--text"
                          depressed
                          @click="filterDistrict(item.title)"
                        >
                          {{ item.title }}
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </th>
              <th v-else class="text-left green--text">
                DISTRICT
              </th>
              <th class="text-left green--text">
                LOCALITY
              </th>
              <th class="text-left green--text">
                PLATFORM
              </th>
              <th class="text-left green--text">
                COURSE
              </th>
              <th class="text-left green--text">
                PURCHASED DATE
              </th>
              <!-- <th class="text-left green--text">
                                UNLOCKED STATUS
                            </th> -->
              <th v-if="!filter" class="text-left green--text">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed small v-bind="attrs" v-on="on" text>
                      <span class="text-left green--text"
                        ><b>PAYMENT</b> <v-icon left top>mdi-menu-down</v-icon>
                      </span>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="(item, index) in status" :key="index">
                      <v-list-item-title
                        ><v-btn
                          class="text-left green--text"
                          depressed
                          @click="filterPayment(item.status)"
                          >{{ item.status }}</v-btn
                        >
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </th>
              <th v-else class="text-left green--text">
                PAYMENT
              </th>
              <th class="text-left green--text">
                ACTION
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in purchases" :key="item._id">
              <td>{{ index + 1 }}</td>
              <td v-if="item.user != null">
                <b>{{ item.user.name }}</b>
              </td>
              <td v-if="item.user != null">{{ item.user.phone }}</td>
              <td v-if="item.user != null && item.user.email">
                {{ item.user.email }}
              </td>
              <td v-else>NA</td>
              <td v-if="item.user != null && item.user.district">
                {{ item.user.district }}
              </td>
              <td v-else>NA</td>
              <td v-if="item.user != null && item.user.locality">
                {{ item.user.locality }}
              </td>
              <td v-else>NA</td>
              <td v-if="item.platform == 0 && item.platform">IOS</td>
              <td v-if="item.platform == 1 && item.platform">Android</td>
              <td v-if="!item.platform">Android / IOS</td>

              <td v-if="item.user != null">{{ item.course.course }}</td>
              <td v-if="item.created">
                {{ item.created | moment("Do MMM YYYY, h:mm:ss a") }}
              </td>
              <td v-else>NA</td>
              <!-- <td v-if="item.unlocked"> <b>Unlocked</b> </td>
                            <td v-else></td> -->
              <td v-if="item.payment" class="purple--text">
                {{ item.payment }}
              </td>
              <td v-else class="red--text">{{ item.payment }}</td>
              <!-- <td>{{ item.user.locality }}</td>   -->
              <td width="100px">
                <v-icon
                  class="green--text pr-3"
                  @click="gotoDetails(item.user.phone)"
                  >search</v-icon
                >
                <v-icon
                  v-if="ADMIN"
                  class="pink--text mx-1"
                  @click="deleteCourse(item._id)"
                  >delete</v-icon
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-flex>
  </v-layout>
</template>

<script>
import { BASE_URL } from "../config";
import Axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      userDetails: {},
      purchases: [],
      sorted_list: [],
      count: "",
      distCount: "",
      phone_number: "",
      status: [{ status: true }, { status: false }],
      items: [
        { title: "Alappuzha" },
        { title: "Ernakulam" },
        { title: "Idukki" },
        { title: "Kannur" },
        { title: "Kasaragod" },
        { title: "Kollam" },
        { title: "Kottayam" },
        { title: "Kozhikode" },
        { title: "Malappuram" },
        { title: "Palakkad" },
        { title: "Pathanamthitta" },
        { title: "Thiruvananthapuram" },
        { title: "Thrissur" },
        { title: "Wayanad" },
        { title: "Others" },
      ],
      district: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      dateOne: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menuOne: false,
      modalOne: false,
      menu2One: false,
      filter: false,
      loading: false,
    };
  },
  async mounted() {
    {
      this.loading = true;
      let url = BASE_URL + "/all-purchase-request-ta";
      let { data } = await Axios.get(url);
      for (let i = 0; i < data.length; i++) {
        if (data[i].course != null) {
          if (data[i].course.categoryName == "MOULDING MINDS MAGICALLY") {
            this.purchases.push(data[i]);
          }
        }
      }
      // this.purchases = data
      // this.purchases.sort((a,b)=> (a.course < b.course ? 1 : -1))
      this.count = this.purchases.length;
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters(["ADMIN"]),
  },
  watch: {
    async phone_number(newphone_number, oldphone_number) {
      this.loading = true;
      if (newphone_number.length > 0) {
        let url = BASE_URL + "all-filtered-request-ta?phone=" + newphone_number;
        let { data } = await Axios.get(url);
        this.purchases = data;
        this.loading = false;
      }
    },
  },
  methods: {
    search() {
      if (this.searchText != "") {
        this.$router.push({
          name: "SearchResult",
          params: { phone: this.phone_number },
        });
      }
    },
    gotoDetails(phone) {
      this.$router.push({ name: "SearchResult", params: { phone: phone } });
    },
    async filterDistrict(district) {
      this.loading = true;
      let url = BASE_URL + "/all-purchase-request-ta";
      let { data } = await Axios.get(url);
      this.district = district;
      let filteredPurchases = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].course != null) {
          if (data[i].course.categoryName == "MOULDING MINDS MAGICALLY") {
            filteredPurchases.push(data[i]);
          }
        }
      }
      filteredPurchases = filteredPurchases.filter((item) => {
        if (item.user != null && item.user.district != null) {
          return item.user.district.toUpperCase() == district.toUpperCase();
        } else if (item.user != null && district == "Others") {
          return item.user.district == undefined;
        }
      });
      this.distCount = filteredPurchases.length;
      this.loading = false;
      return (this.purchases = filteredPurchases);
    },
    async filterPayment(status) {
      let url = BASE_URL + "/all-purchase-request-ta";
      let { data } = await Axios.get(url);
      let filteredPayment = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].course != null) {
          if (data[i].course.categoryName == "MOULDING MINDS MAGICALLY") {
            filteredPayment.push(data[i]);
          }
        }
      }
      filteredPayment = filteredPayment.filter((item) => {
        return item.payment == status;
      });
      this.count = filteredPayment.length;
      this.loading = false;
      return (this.purchases = filteredPayment);
    },
    deleteCourse(id) {
      swal({
        title: "Are you sure?",
        text: "The Course will be deleted permanantly!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let url = BASE_URL + "/delete-course-ta?id=" + id;
          let { data } = await Axios.delete(url);
          await swal("Deleted", "Course deleted successfully!", {
            icon: "success",
          });
          this.$router.go("");
        } else {
          swal("The Course was not deleted!");
        }
      });
    },
    async filterDate(from, to) {
      this.loading = true;
      if (from > to) {
        this.$toasted.error("From DATE must not larger than To DATE!!!");
      } else {
        let payload = {
          previous: from,
          next: to,
        };
        let url = BASE_URL + "/auth/dateSort";
        let { data } = await Axios.post(url, payload);
        console.log(data.ascc);
        this.purchases = data.mcube.reverse();
        this.filter = true;
        this.loading = false;
      }
    },
  },
};
</script>
