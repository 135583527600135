<template>
  <v-layout row wrap>
    <v-flex xs12 md8>
      <div class="white mt-8">
        <h3 class="green--text  py-1" style="text-align:center">
          USERS DETAILS
        </h3>
      </div>
      <v-simple-table class="mt-10">
        <template v-slot:default>
          <tbody>
            <tr>
              <td><b>NAME</b></td>
              <td>{{ userDetails.name }}</td>
            </tr>
            <tr>
              <td><b>PHONE</b></td>
              <td>
                <span v-if="userDetails.country_code">{{
                  userDetails.country_code
                }}</span>
                {{ userDetails.phone }}
              </td>
            </tr>
            <tr>
              <td><b>EMAIL</b></td>
              <td>{{ userDetails.email }}</td>
            </tr>
            <tr v-if="userDetails.district">
              <td><b>DISTRICT</b></td>
              <td>{{ userDetails.district }}</td>
            </tr>
            <tr v-if="userDetails.addressLine1">
              <td><b>ADDRESS LINE 1</b></td>
              <td>{{ userDetails.addressLine1 }}</td>
            </tr>
            <tr v-if="userDetails.locality">
              <td><b>LOCALITY</b></td>
              <td>{{ userDetails.locality }}</td>
            </tr>
            <tr v-if="userDetails.addressLine2">
              <td><b>ADDRESS LINE 2</b></td>
              <td>{{ userDetails.addressLine2 }}</td>
            </tr>
            <tr v-if="userDetails.pincode">
              <td><b>PINCODE</b></td>
              <td>{{ userDetails.pincode }}</td>
            </tr>
            <tr v-if="userDetails.addressLine3">
              <td><b>ADDRESS LINE 3</b></td>
              <td>{{ userDetails.addressLine3 }}</td>
            </tr>
            <tr v-if="userDetails.po">
              <td><b>POST OFFICE</b></td>
              <td>{{ userDetails.po }}</td>
            </tr>
            <tr v-if="userDetails.country">
              <td><b>COUNTRY</b></td>
              <td>{{ userDetails.country }}</td>
            </tr>
            <tr>
              <td><b>DEVICE ID </b></td>
              <td class="pink--text">{{ userDetails.device_id }}</td>
            </tr>
            <tr v-if="userDetails.verified">
              <td><b>VERIFIED </b></td>
              <td class="indigo--text">{{ userDetails.verified }}</td>
            </tr>
            <tr v-if="userDetails.isVerified">
              <td><b>VERIFIED </b></td>
              <td class="indigo--text">{{ userDetails.isVerified }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-flex xs12 class="mt-3">
        <v-btn outlined color="green" @click="details(userDetails._id)"
          >EDIT USER</v-btn
        >
      </v-flex>
      <v-flex xs12 v-if="ADMIN">
        <p class="white pa-3 caption mt-3"><b>UNLOCK COURSES</b></p>
        <v-btn
          @click="academic"
          outlined
          color="green"
          v-if="this.type == 'ACADEMIC'"
          >ACADEMIC</v-btn
        ><v-btn @click="academic" outlined color="grey" v-else>ACADEMIC</v-btn>
        <v-btn
          @click="ta"
          outlined
          color="green"
          v-if="this.type == 'TA'"
          class="ml-5"
          >TALENT ACADEMY</v-btn
        ><v-btn @click="ta" outlined color="grey" v-else class="ml-5"
          >TALENT ACADEMY</v-btn
        >
        <v-flex xs12 v-if="ADMIN">
          <!-- <v-btn @click="unlockAllCourse" outlined color="red" class="ma-2">UNLOCK ALL COURSES</v-btn>
                    <v-btn @click="unlockAllCourseTa" outlined color="red" class="ml-5">UNLOCK ALL COURSES (TA)</v-btn>  -->
        </v-flex>
      </v-flex>
      <v-flex xs12 v-if="type == 'ACADEMIC'">
        <p class="white pa-2 caption mt-2"><b>UNLOCK ACADEMIC COURSE</b></p>
        <v-text-field
          v-model="searchCourse"
          label="search"
          single-line
          outlined
        ></v-text-field>
        <v-simple-table class="mt-10">
          <template v-slot:default>
            <tbody>
              <tr v-for="item in filteredCourses" :key="item._id">
                <td>
                  <b>{{ item.name }}</b>
                </td>
                <td>
                  <v-btn class="green white--text" @click="unlockCourse(item)"
                    >UNLOCK</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
      <v-flex xs12 v-if="type == 'TA'">
        <p class="white pa-2 caption mt-2"><b>UNLOCK TALENT ACADEMY</b></p>
        <v-text-field
          v-model="searchCourseTa"
          label="search"
          single-line
          outlined
        ></v-text-field>
        <v-simple-table class="mt-10">
          <template v-slot:default>
            <tbody>
              <tr v-for="item in filteredCoursesTa" :key="item._id">
                <td>
                  <b>{{ item.course }}</b>
                </td>
                <td>
                  <v-btn class="green white--text" @click="unlockCourseTa(item)"
                    >UNLOCK</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
    </v-flex>

    <v-flex xs4 pl-3>
      <div class="white mt-8">
        <h3 class="green--text text-center py-1">
          PURCHASE HISTORY
        </h3>
      </div>
      <p class="caption pa-2 white mt-3"><b>ACADEMIC COURSES</b></p>
      <v-simple-table class="mt-2 ">
        <template v-slot:default>
          <thead v-if="purchases.length > 0">
            <tr>
              <th class="text-left green--text">
                #
              </th>

              <th class="text-left green--text">
                COURSE
              </th>
              <th class="text-left green--text">
                DEVICE
              </th>
              <th v-if="ADMIN" class="text-left green--text">
                ACTION
              </th>
            </tr>
          </thead>
          <tbody v-if="purchases.length > 0">
            <tr v-for="(item, index) in purchases" :key="item._id">
              <td>{{ index + 1 }}</td>
              <td>{{ item.course }}</td>
              <td class="pink--text">{{ item.deviceId }}</td>
              <td v-if="ADMIN">
                <v-icon class="red--text" @click="deleteCourse(item._id)"
                  >mdi-delete</v-icon
                >
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr class="text-center " style="color:crimson">
              No purchases yet!!
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p class="caption pa-2 white mt-3"><b>TALENT ACADEMY</b></p>
      <v-simple-table class="mt-2 ">
        <template v-slot:default>
          <thead v-if="purchasesTa.length > 0">
            <tr>
              <th class="text-left green--text">
                #
              </th>

              <th class="text-left green--text">
                COURSE
              </th>
              <th class="text-left green--text">
                DEVICE
              </th>
              <th v-if="ADMIN" class="text-left green--text">
                ACTION
              </th>
            </tr>
          </thead>
          <tbody v-if="purchasesTa.length > 0">
            <tr v-for="(item, index) in purchasesTa" :key="item._id">
              <td>{{ index + 1 }}</td>
              <td>{{ item.course.course }}</td>
              <td class="pink--text">{{ item.deviceId }}</td>
              <td v-if="ADMIN">
                <v-icon class="red--text" @click="deleteCourseTa(item._id)"
                  >mdi-delete</v-icon
                >
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr class="text-center" style="color:crimson">
              No purchases yet!!
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-flex>
  </v-layout>
</template>

<script>
import { BASE_URL } from "../config";
import Axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      userDetails: [],
      purchases: [],
      purchasesTa: [],
      courses: [],
      coursesTa: [],
      searchCourse: "",
      searchCourseTa: "",
      type: "",
    };
  },
  methods: {
    async unlockCourse(item) {
      let url = BASE_URL + "/unlock-course";

      let payload = {
        code: item.code,
        user: this.userDetails._id,
        deviceId: this.userDetails.device_id,
        unlocked: true,
      };
      let { data } = await Axios.post(url, payload);
      alert("COURSE UNLOCKED");
      this.$router.go("");
    },
    async unlockCourseTa(item) {
      let url = BASE_URL + "/unlock-course-ta";

      let payload = {
        course: item._id,
        user: this.userDetails._id,
        deviceId: this.userDetails.device_id,
        unlocked: true,
      };
      let { data } = await Axios.post(url, payload);
      alert("COURSE UNLOCKED");
    },
    deleteCourse(id) {
      swal({
        title: "Are you sure?",
        text: "The Course will be deleted permanantly!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let url = BASE_URL + "/delete-course?id=" + id;
          let { data } = await Axios.delete(url);
          await swal("Deleted", "Course deleted successfully!", {
            icon: "success",
          });
          this.$router.go("");
        } else {
          swal("The Course was not deleted!");
        }
      });
    },
    async deleteCourseTa(id) {
      swal({
        title: "Are you sure?",
        text: "The Course will be deleted permanantly!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let url = BASE_URL + "/delete-course-ta?id=" + id;
          let { data } = await Axios.delete(url);
          await swal("Deleted", "Course deleted successfully!", {
            icon: "success",
          });
          this.$router.go("");
        } else {
          swal("The Course was not deleted!");
        }
      });
    },
    async unlockAllCourseTa() {
      this.loading = true;
      for (let i = 0; i < this.coursesTa.length; i++) {
        let url = BASE_URL + "/unlock-course-ta";
        // let url ="http://localhost:5000/api/v1/unlock-course-ta"
        let payload = {
          course: this.coursesTa[i]._id,
          user: this.userDetails._id,
          deviceId: this.userDetails.device_id,
        };

        let { data } = await Axios.post(url, payload);
      }
      this.loading = false;
      alert("ALL COURSES ARE UNLOCKED");
    },
    async unlockAllCourse() {
      this.loading = true;
      for (let i = 0; i < this.coursesTa.length; i++) {
        let url = BASE_URL + "/unlock-course";
        let payload = {
          code: this.courses[i].code,
          user: this.userDetails._id,
          deviceId: this.userDetails.device_id,
        };

        console.log(payload);
        let { data } = await Axios.post(url, payload);
      }

      this.loading = false;
      alert("ALL COURSES ARE UNLOCKED");
      this.$router.go("");
    },
    academic() {
      this.type = "ACADEMIC";
    },
    ta() {
      this.type = "TA";
    },
    details(id) {
      this.$router.push({ name: "EditUser", params: { id: id } });
    },
  },
  async mounted() {
    {
      let url =
        BASE_URL + "/auth/users/phone?phone=" + this.$route.params.phone;
      let { data } = await Axios.get(url);
      this.userDetails = data;
    }
    {
      let url = BASE_URL + "/user-purchase?id=" + this.userDetails._id;
      let { data } = await Axios.get(url);
      this.purchases = data;
    }
    {
      let url = BASE_URL + "/user-purchase-ta?id=" + this.userDetails._id;
      let { data } = await Axios.get(url);
      this.purchasesTa = data;
    }
    {
      let url = BASE_URL + "/course-details/all";
      let { data } = await Axios.get(url);
      this.courses = data;
    }
    {
      let url = BASE_URL + "/talent-academy/course";
      let { data } = await Axios.get(url);
      this.coursesTa = data;
    }
  },
  computed: {
    filteredCourses() {
      if (this.searchCourse == "") {
        return [];
      } else {
        return this.courses.filter((data) =>
          data.name.toLowerCase().includes(this.searchCourse.toLowerCase())
        );
      }
    },
    filteredCoursesTa() {
      if (this.searchCourseTa == "") {
        return [];
      } else {
        return this.coursesTa.filter((data) =>
          data.course.toLowerCase().includes(this.searchCourseTa.toLowerCase())
        );
      }
    },
    ...mapGetters(["ADMIN"]),
  },
};
</script>
