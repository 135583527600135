<template>
  <v-app class="custom-style pl-10 pr-10" style="height:100%">
    <v-main style="height:100%" v-if= "LOGIN || ADMIN">
    <app-tool-bar></app-tool-bar>
      <div class="px-10">
        
        <router-view></router-view> 
      </div>
      <home/>
    </v-main>
    <v-main v-else>
      <LoginCard/>
    </v-main>
  </v-app>
</template>

<script>

import {mapGetters} from 'vuex'
import LoginCard from '@/components/LoginCard.vue';
import Toolbar from '@/components/Toolbar.vue';
import Home from './views/Home.vue';


export default {
  name: 'App',
  computed : {
    ...mapGetters(["LOGIN","ADMIN"])
  },
  components: {
    AppToolBar : Toolbar,
    LoginCard,
    Home
  },

  data: () => ({
    //
  }),
};
</script>

<style>
.custom-style{
    font-family: 'Montserrat', sans-serif;
    background-color: #eceff1 !important;
}
</style>
